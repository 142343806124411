import 'regenerator-runtime/runtime'

import api from "@/services/apiService"

class QueueService {

  // startInstance(instanceId) {
  //   return new Promise((resolve, reject) => {
  //     api.post(process.env.VUE_APP_API_MEMBER_TICKET + 'instance/start/' + instanceId, {
  //       force: true
  //     }, {
  //       customToken: process.env.VUE_APP_API_MEMBER_SECRET_TOKEN
  //     })
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     })
  //   })
  // }

  // stopInstance(instanceId) {
  //   return new Promise((resolve, reject) => {
  //     api.post(process.env.VUE_APP_API_MEMBER_TICKET + 'instance/stop/' + instanceId, {
  //       force: true
  //     }, {
  //       customToken: process.env.VUE_APP_API_MEMBER_SECRET_TOKEN
  //     })
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     })
  //   })
  // }

  // getWaiting(instanceIds) {
  //   return new Promise((resolve, reject) => {
  //     api.post(process.env.VUE_APP_API_MEMBER_QUEUE + 'instance/waiting', {instanceIds}, {
  //       customToken: process.env.VUE_APP_API_MEMBER_SECRET_TOKEN
  //     })
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     })
  //   })
  // }

  // stopQueues(instanceId) {
  //   return new Promise((resolve, reject) => {
  //     api.post(process.env.VUE_APP_API_MEMBER_QUEUE + 'instance/stop-queue/' + instanceId, {}, {
  //       customToken: process.env.VUE_APP_API_MEMBER_SECRET_TOKEN
  //     })
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     })
  //   })
  // }

  // totalQueue(roundId) {
  //   return new Promise((resolve, reject) => {
  //     api.get(process.env.VUE_APP_API_MEMBER_QUEUE + 'instance/total-queue/' + roundId, {
  //       customToken: process.env.VUE_APP_API_MEMBER_SECRET_TOKEN
  //     })
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     })
  //   })
  // }

  // deleteQueues(roundId) {
  //   return new Promise((resolve, reject) => {
  //     api.delete(process.env.VUE_APP_API_MEMBER_QUEUE + 'instance/total-queue/' + roundId, {
  //       customToken: process.env.VUE_APP_API_MEMBER_SECRET_TOKEN
  //     })
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     })
  //   })
  // }
}

export default new QueueService()
